/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        "./assets/src/js/**/*.js",
        "./templates/**/*.html.twig",
        "./src/Form/Type/**/*.php",
        "./src/Controller/**/*.php",
        "./vendor/acc-digital/**/*.html.twig",
    ],
    safelist: process.env.NODE_ENV === "development" ? [{ pattern: /.*/ }] : [],
    darkMode: "class",
    theme: {
        screens: {
            xs: "380px",
            sm: "480px",
            md: "768px",
            lg: "1024px",
            xl: "1280px",
            xxl: "1440px",
            "h-lg": { raw: "(min-height: 820px)" },
        },
        container: {
            center: true,
            padding: {
                DEFAULT: "1rem",
                lg: "1.25rem",
            },
            screens: {
                xs: "100%",
                sm: "100%",
                md: "768px",
                lg: "1024px",
                xl: "1280px",
                xxl: "1440px",
            },
        },
        colors: {
            transparent: "transparent",
            current: "currentColor",
            white: "#ffffff",
            black: "#000000",
            primary: "#3E60CB",
            sand: "#DCD5B9",
            road: "#CECECB",
            water: "#6CA38B",
            air: "#87B5CB",
            rail: "#DE6B08",
            alabaster: "#F1EFE4",
            admin: {
                blue: {
                    200: "#C0DEED",
                    500: "#87B5CB",
                },
                orange: {
                    200: "#F2A663",
                    500: "#DE6B08",
                },
            },
        },
        fontFamily: {
            apercu: ["Apercu Pro", "ui-sans-serif", "system-ui"],
            "saa-series": ["Saa Series C", "ui-sans-serif", "system-ui"],
        },
        fontWeight: {
            regular: 400,
        },
        fontSize: {
            12: ["0.75rem", { lineHeight: "1.3" }],
            14: ["0.875rem", { lineHeight: "1.3" }],
            16: ["1rem", { lineHeight: "1.125" }],
            18: ["1.125rem", { lineHeight: "1.25" }],
            20: ["1.25rem", { lineHeight: "1.25" }],
            24: ["1.5rem", { lineHeight: "1.25" }],
            28: ["1.75rem", { lineHeight: "1.25" }],
            30: ["1.875rem", { lineHeight: "1.1" }],
            32: ["2rem", { lineHeight: "1.1875" }],
            36: ["2.25rem", { lineHeight: "1.08" }],
            40: ["2.5rem", { lineHeight: "1.1" }],
            42: ["2.625rem", { lineHeight: "1.095" }],
            48: ["3rem", { lineHeight: "0.92" }],
            60: ["3.75rem", { lineHeight: "1.1" }],
            78: ["4.875rem", { lineHeight: "0.85" }],
            100: ["6.25rem", { lineHeight: "0.85" }],
        },
        lineHeight: {
            none: 1,
            18: "1.125rem",
            24: "1.5rem",
            30: "1.875rem",
            36: "2.25rem",
            38: "2.375rem",
            41: "2.5625rem",
            44: "2.75rem",
            46: "2.875rem",
            54: "3.375rem",
            66: "4.125rem",
        },
        extend: {
            margin: {
                5.5: "1.375rem",
                7.5: "1.875rem",
                15: "3.75rem",
                18: "4.5rem",
                26: "6.5rem",
                38: "9.5rem",
                46: "11.5rem",
                50: "12.5rem",
                68: "17rem",
            },
            padding: {
                4.5: "1.125rem",
                6.5: "1.625rem",
                7.5: "1.875rem",
                10.5: "2.625rem",
                13: "3.25rem",
                15: "3.75rem",
                17: "4.25rem",
                19: "4.75rem",
                22: "5.5rem",
                25: "6.25rem",
                30: "7.5rem",
                42: "10.5rem",
                46: "11.5rem",
                50: "12.5rem",
            },
            height: {
                22: "5.5rem",
            },
            width: {
                18: "4.5rem",
                22: "5.5rem",
            },
            gap: {
                2.5: "0.625rem",
                7.5: "1.875rem",
                15: "3.75rem",
            },
            space: {
                7.5: "1.875rem",
            },
            aspectRatio: {
                "3/4": "3 / 4",
            },
            borderWidth: {
                3: "0.1875rem",
            },
            borderRadius: {
                4: "1rem",
                5: "1.25rem",
                7.5: "1.875rem",
                8.5: "2.125rem",
                10: "2.5rem",
                12: "3rem",
                15: "3.75rem",
                16: "4rem",
                20: "5rem",
            },
            opacity: {
                85: "0.85",
            },
            gridTemplateRows: {
                "0fr": "0fr",
                "1fr": "1fr",
            },
            gridTemplateColumns: {
                24: "repeat(24, minmax(0, 1fr))",
            },
            transitionProperty: {
                accordion: "grid-template-rows",
            },
        },
    },
    future: {
        hoverOnlyWhenSupported: true,
    },
    plugins: [],
};
