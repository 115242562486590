import "./components/tailwind";
import "./components/gsap";
import "./components/lenis";
import "./components/swiper";
import "./components/alpine";
import "./components/lazyLoading";
import "./components/plyr";
import * as components from "./components";

// ACC namespace for global configs
const ACC = window.ACC || {
    vars: {},
    methods: {
        initializeComponents: () => {
            const componentNodes =
                Array.from(document.querySelectorAll("[data-component]")) || [];
            componentNodes.forEach((node) => {
                const componentName = node.getAttribute("data-component");
                let props = node.getAttribute("data-props");
                if (props) props = JSON.parse(props);

                if (!!components[componentName]) {
                    /* eslint-disable no-new */
                    new components[componentName](node, props);
                }
            });
        },
    },
};

// load/initialize complex js-components
document.addEventListener("DOMContentLoaded", () =>{
    ACC.methods.initializeComponents();

    let cookieSettingButton = document.getElementsByClassName(
        "data-cookie-consent-setting"
    );
    cookieSettingButton[0].setAttribute("data-cookie-consent-setting", "true");
});

scc.onOpenCookieSettings(() => {
    const event = new CustomEvent("lenis-control", {
        detail: { action: "disable" },
    });
    document.dispatchEvent(event);
});
scc.onCloseCookieSettings(() => {
    const event = new CustomEvent("lenis-control", {
        detail: { action: "enable" },
    });
    document.dispatchEvent(event);
});
