// Alpine
import Alpine from "alpinejs";
import persist from "@alpinejs/persist";

// Plugins
Alpine.plugin(persist);

// Add to window
window.Alpine = Alpine;

// Initialize alpine
window.Alpine.start();
